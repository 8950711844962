{
    const VaultItemPropertiesConstant = {
        METADATA: {
            TAG1: {
                NAME: "tag1",
                CAPTION: "Tag1"
            },
            TAG2: {
                NAME: "tag2",
                CAPTION: "Tag2"
            },
            TAG3: {
                NAME: "tag3",
                CAPTION: "Tag3"
            },
            TAG4: {
                NAME: "tag4",
                CAPTION: "Tag4"
            },
            TAG5: {
                NAME: "tag5",
                CAPTION: "Tag5"
            },
            LTAG1: {
                NAME: "ltag1",
                CAPTION: "LTag1"
            },
            LTAG2: {
                NAME: "ltag2",
                CAPTION: "LTag2"
            },
            CREATEDON: {
                NAME: "createdOn",
                CAPTION: "Created On"
            },
            UPDATEDON: {
                NAME: "updatedOn",
                CAPTION: "Updated On"
            }
        },
        FILEINFO: {
            FILENAME: {
                NAME: "fileName",
                CAPTION: "File Name"
            },
            FILESTATUS: {
                NAME: "status",
                CAPTION: "File Status"
            },
            URL: {
                NAME: "uploadUrl",
                CAPTION: "URL"
            },
            CREATEDON: {
                NAME: "createdOn",
                CAPTION: "Created On"
            },
            UPDATEDON: {
                NAME: "updatedOn",
                CAPTION: "Updated On"
            }
        },
        USERINFO: {
            FIRSTNAME: {
                NAME: "firstName",
                CAPTION: "First Name"
            },
            LASTNAME: {
                NAME: "lastName",
                CAPTION: "Last Name"
            },
            EMAILADDRESS: {
                NAME: "emailAddress",
                CAPTION: "Email"
            }
        }
    };

    angular.module('adminApp')
        .constant('VaultItemPropertiesConstant', VaultItemPropertiesConstant);
}
